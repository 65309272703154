var site = site || {};

site.productView = site.productView || {};

site.productView.search = {
  init: function (productsData) {
    var $searchProductGrids = $('#product-results .results, #bestseller-results .results');

    if (!$searchProductGrids.length) {
      return;
    }

    $searchProductGrids.each(function (gridCount, grid) {
      var $grid = $(grid);
      var $searchProducts = $('.product-brief', $grid);
      // get row count
      var searchRows = _.groupBy($searchProducts, function (element, index) {
        // 2 in a row
        return Math.floor(index / 2);
      });

      searchRows = _.toArray(searchRows);

      // event out row height
      // execute when fonts are ready - @font-face FOUT
      document.fonts.ready.then(function () {
        site.productView.search.rowHeight(searchRows);
        $('#product-results .results').addClass('ready');
      });

      // fix row index to add grid index
      for (var i = 0; i < searchRows.length; i++) {
        var $searchRow = $(searchRows[i]);
        var rowIndex = gridCount + '-' + i;
        var $quickshopContainer =
          '<div class="product-brief__quickshop-container js-quickshop-container" data-row-index="' +
          rowIndex +
          '"></div>';

        $searchRow.each(function (index, product) {
          $(product).attr('data-row-index', rowIndex);
        });
        $searchRow.eq(1).addClass('product-brief--second');
        $searchRow.eq(1).after($quickshopContainer);
        if (i === parseInt(searchRows.length - 1)) {
          // last row
          // check if only one in row
          if ($searchRow.length === 1) {
            $searchRow.eq(0).after($quickshopContainer);
          }
        }
      }

      // update row height on resize
      $(window).on(
        'resize',
        _.debounce(function () {
          site.productView.search.rowHeight(searchRows);
        }, 100)
      );

      // update row height after inv status is updated
      $('.js-inv-status-list').off('inventory_status_updated');
      $('.js-inv-status-list').on('inventory_status_updated', function () {
        var $parentContainer = $(this).parents('.product-brief');
        var rowToResize = _.find(searchRows, function (searchRow) {
          return _.contains(searchRow, $parentContainer[0]);
        });

        site.productView.search.rowHeight([rowToResize]);
      });
    });

    // initialize individual product views
    for (var i = 0; i < productsData.length; i++) {
      site.productView.mobilesearchItem(productsData[i]);
    }
  }, // end init
  rowHeight: function (searchRows) {
    _.each(searchRows, function (searchRow) {
      _.each(searchRow, function (productContainer) {
        $(productContainer).removeAttr('style');
      });
    });
    // misc flag - extra padding
    $(searchRows).each(function (index, row) {
      var hasFlag = 0;
      var flagHeight = '19px';

      $(row).each(function (index, product) {
        if ($(product).find('.product-brief__misc-flag').length) {
          hasFlag++;
          flagHeight = $(product).find('.product-brief__misc-flag').outerHeight(true);
        }
      });
      $(row).each(function (index, product) {
        if (hasFlag && $(product).find('.product-brief__misc-flag').length === 0) {
          // check if the spaceer already exists before attempting to insert one
          if (!$(product).find('.product-brief__misc-flag-spacer')) {
            var $spacer = $('<div class="product-brief__misc-flag-spacer">');

            $spacer.height(flagHeight);
            $(product).find('.product-brief__header').before($spacer);
          }
        }
      });
    });

    for (var x in searchRows) {
      var searchRow = searchRows[x];
      var maxProductHeight = Math.max.apply(
        null,
        $(searchRow)
          .map(function () {
            return $(this).outerHeight();
          })
          .get()
      );

      $(searchRow).outerHeight(maxProductHeight);
    }
  }
};

site.productView.mobilesearchRowHeights = function () {
  var $searchProducts = $('.product-brief');
  var searchRows = _.groupBy($searchProducts, function (element, index) {
    // 2 in a row
    return Math.floor(index / 2);
  });

  searchRows = _.toArray(searchRows);
  $searchProducts.removeAttr('style');
  site.productView.search.rowHeight(searchRows);
};

// duplicate problem
site.productView.mobilesearchItem = function (productData) {
  var that = {
    productData: productData
  };
  var p = that.productData;
  var $prodContainer = $(".product-brief[data-product-id='" + p.PRODUCT_ID + "']");

  if ($prodContainer.length === 0) {
    return;
  }
  $prodContainer.each(function (index, product) {
    var $product = $(product);
    var pos = $product.hasClass('product-brief--second') ? 1 : 0;
    var rowIndex = $product.attr('data-row-index');
    var $qsContainer = $(".js-quickshop-container[data-row-index='" + rowIndex + "']");
    var qs = site.productView.inlineQuickshopMobile({
      productData: p,
      position: pos,
      $productContainer: $product,
      $quickshopContainer: $qsContainer
    });
    var $showButton = $(".js-quickshop-show[data-product-id='" + p.PRODUCT_ID + "']", $product);
    var $hideButton = $(".js-quickshop-hide[data-product-id='" + p.PRODUCT_ID + "']", $product);
    var $prodImg = $(".product-brief__image[data-product-id='" + p.PRODUCT_ID + "']", $product);

    $showButton.off('click');
    $showButton.on('click', function (evt) {
      evt.preventDefault();
      qs.launch();
      $qsContainer.attr('data-prod-id', $showButton.data('product-id'));
      // hide all hide buttons
      $('.js-quickshop-hide').addClass('hidden');
      // show all show buttons
      $('.js-quickshop-show').removeClass('hidden');
      $hideButton.removeClass('hidden');
      $showButton.addClass('hidden');
      site.setAddToBagShoppableByInventoryStatus(p.defaultSku, 'js-add-to-cart');
    });

    $hideButton.off('click');
    $hideButton.on('click', function (evt) {
      evt.preventDefault();
      qs.close();
      $hideButton.addClass('hidden');
      $showButton.removeClass('hidden');
    });

    $prodImg.off('sku:select');
    $prodImg.on('sku:select', function (e, skuData) {
      var skuImg = Array.isArray(skuData.MEDIUM_IMAGE)
        ? skuData.MEDIUM_IMAGE[0]
        : skuData.MEDIUM_IMAGE;

      $(this).css('background-image', 'url(' + skuImg + ')');
      e.stopPropagation;
    });
  });

  return;
};

site.productView.mobilesearchRebuild = function (productData) {
  var $searchProducts = $('.product-brief');
  var $quickshop = $('.js-quickshop-container');

  $searchProducts.removeClass('product-brief--second');
  // remove quickshop
  $quickshop.remove();
  site.productView.search.init(productData);
};
